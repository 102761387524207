h2, h3, h4, ul, ol, hr, table, p {
  margin-bottom: 1rem;
}

ul p, ol p {
  margin-bottom:0;
}

table p {
  margin-bottom:0;
}

table {
  border-collapse: separate;
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: #fff;
  border-spacing: 0;
  border-radius: 0.25rem;
  overflow: hidden;
  border: 1px solid #dee2e6;
}

table thead tr th,
table tfoot tr td {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
}

table thead th {
  text-align: left;
  font-weight: 600;
}

table th {
  background-color: #f8f9fa;
}
table th,
table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #dee2e6;
}

table tr:nth-child(even) {

}

table td {
  border-right: 1px solid #dee2e6;
}

table td:last-child {
  border-right: 0;
}

.video-embed {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  margin-bottom: 2rem;
}
.video-embed iframe,
.video-embed object,
.video-embed embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.fade {
  transition:ease opacity .5s;
  transition-delay: .2s;
}