.load {
  opacity: 0;
}

.loaded .load {
  opacity: 1;
}

.menu-button {
  background: none;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 10px;
  cursor: pointer;
}

.menu-bar {
  height: 2px;
  width: 30px;
  display: inline-block;
  background-color: white;
  transform-origin: 50% 50%;
  transition: all 0.2s ease-out;
}

.menu-open .menu-bar:first-child {
  transform: translateY(4px) rotateZ(20deg);
}

.menu-open .menu-bar:last-child {
  transform: translateY(-4px) rotateZ(-20deg);
}



