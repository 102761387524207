.bg-auto-75 {
  background-size: 75% auto;
}

.bg-cover {
  background-size: cover;
}

.bg-contain {
  background-size: contain;
}

.landingIntro p {
  margin:0;
}