.modal-overlay {
  z-index: 10;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0);
  width: 100vw;
  height: 100%;
}

.modal {
  z-index: 20;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity:0;
  transition: all 0.15s ease-in-out;
  
}


.modal.ReactModal__Content--after-open {
  opacity: 1;
}

.modal.ReactModal__Content--before-close {
  opacity: 0;
}